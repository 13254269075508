/** Global Styling */

@import "colors.scss";

body.no-scroll {
  overflow: hidden;
}

@media only screen and (max-width: 639px) {
  .height-section {
    min-height: fit-content !important;
  }
}

.height-section {
  min-height: 750px;
}

.bg-gradient-green {
  background: linear-gradient(180deg, #9dde4e 55.21%, #7db637 100%);
}

.bg-base-green {
  background: rgb(157, 222, 78);
}

@media only screen and (max-width: 639px) {
  .bg-xs-transparent {
    background: transparent !important;
  }
  .bg-xs-transparent-green {
    background: rgba(157, 222, 78, 0.8);
  }

  .bg-xs-transparent-white {
    background: rgba(255, 255, 255, 0.8);
  }
}

.font-cholla-slab-ot-no-weight {
  font-family: cholla-slab, serif;
  font-style: normal;
}

.font-cholla-slab-ot-bold {
  font-family: cholla-slab, serif;
  font-weight: 700;
  font-style: normal;
}

.font-cholla-slab-ot-regular-oblique {
  font-family: cholla-slab, serif;
  font-weight: 400;
  font-style: italic;
}

.font-cholla-slab-ot-bold-oblique {
  font-family: cholla-slab, serif;
  font-weight: 700;
  font-style: italic;
}

.font-cholla-slab-ot-regular {
  font-family: cholla-slab, serif;
  font-weight: 400;
  font-style: normal;
}

.font-cholla-unicase-ot-regular {
  font-family: cholla-unicase, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.header-link {
  font-family: cholla-slab, serif;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
}

.font-lato {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: 400;
}

.font-lato-no-weight {
  font-family: Lato, serif;
  font-style: normal;
}

.text-xxs {
  font-size: 0.7rem;
  line-height: 1rem;
}
