// Tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// Augusto's custom tailwind
@import "./assets/augusto-custom-tailwind";

// Icons: Font Awesome
@import "https://use.fontawesome.com/releases/v5.13.1/css/all.css";

// Font: Lato
@import "https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap";

// Font: Bungee
@import "https://fonts.googleapis.com/css?family=Bungee&display=swap";

// Font: Bungee Shade
@import "https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap";

// Font: Bitter
@import "https://fonts.googleapis.com/css?family=Bitter:400,500,600,700,800,900&display=swap";

// More Fonts
@import "https://use.typekit.net/yck5dwu.css";

// Global Styling
@import "./assets/global";
